const phraseToProperCase = (f) => {
  return f
    .split(/(?=[A-Z])/)
    .map((s) => s.replace(/(\b[a-zA-Z])/g, (x) => x.toUpperCase()))
    .join(" ");
};

const camelcaseToDashes = (f) => {
  return f
    .split(/(?=[A-Z])/)
    .map((s) => s.replace(/(\b[a-zA-Z])/g, (x) => x.toLowerCase()))
    .join("-");
};

export { phraseToProperCase, camelcaseToDashes };
