import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, CardTitle, Fade } from "reactstrap";
import "./NotFound.css";

const NotFound = () => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFadeIn(true);
    }, 1000);

    return () => clearTimeout(timeout);
  });

  return (
    <Fade in={fadeIn}>
      <div className="container">
        <div className="NotFound">
          <Card>
            <CardHeader tag="h2">HTTP 404</CardHeader>
            <CardBody>
              <CardTitle>Page Not Found</CardTitle>
            </CardBody>
          </Card>
        </div>
      </div>
    </Fade>
  );
};

export default NotFound;
