import React from "react";
import SeedCollection from "../components/SeedCollection";
import "./Seed.css";

const Seed = (props) => {
  return (
    <div className="Seed">
      <div className="container">
        <SeedCollection {...props} />
      </div>
    </div>
  );
};

export default Seed;
