import React from "react";
import SignalRTest from "../components/SignalRTest";
import "./SignalR.css";

const SignalR = (props) => {
  return (
    <div className="SignalR">
      <div className="container">
        <SignalRTest {...props} />
      </div>
    </div>
  );
};

export default SignalR;
