import axios from "axios";

const baseUrl = process.env.REACT_APP_APPREG_APIBASEURL;
const hostKey = process.env.REACT_APP_AZFUNCTIONS_HOSTKEY;

const getUser = async (id, token = null) => {
  const url = `${baseUrl}/api/userGet?code=${hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: { id }
  });

  return data;
};

const getUsers = async (query, token = null) => {
  const url = `${baseUrl}/api/usersList?code=${hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: query
  });

  return data;
};

const createUser = async (doc, token = null) => {
  const url = `${baseUrl}/api/userInsert?code=${hostKey}`;

  const { data } = await axios({
    method: "POST",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: doc
  });

  return data;
};

const updateUser = async (id, doc, token = null) => {
  const url = `${baseUrl}/api/userUpdate?code=${hostKey}`;
  const { data } = await axios({
    method: "PUT",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: { id },
    data: doc
  });

  return data;
};

const deleteUser = async (id, token = null) => {
  const url = `${baseUrl}/api/userDelete?code=${hostKey}`;

  const { data } = await axios({
    method: "DELETE",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: { id }
  });

  return data;
};

export { getUser, getUsers, createUser, updateUser, deleteUser };
