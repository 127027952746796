import React from "react";
import UserList from "../components/UserList";
import "./Users.css";

const Users = (props) => {
  return (
    <div className="Users">
      <div className="container">
        <UserList {...props} />
      </div>
    </div>
  );
};

export default Users;
